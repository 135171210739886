import {
  Divider,
  Row,
  Col,
  Input,
  Typography,
  DatePicker,
  notification,
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import ButtonReuse from "../../../components/Buttons/Button";
import { useEffect, useRef, useState } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import moment from "moment";
import { getToken } from "../../../auth";
import {
  getCurrencyRates,
  getRates,
} from "../../../stores/actions/getCurrencyAction";
import { useDispatch, useSelector } from "react-redux";
import { editInvoice } from "../../../utils/enums";

const EditInvoice = (props: {
  invoiceData?: any;
  Poid?: any;
  cancelHandler?: any;
  newMonthId?: any;
  invoiceInstanceIndex?: any;
  mastercurrencies?: any;
  poDetails?: any;
  currentInvoiceAmt?: any;
  currencyName?: any;
  getData?:any;
  totalAmount?:any;
}) => {
  const { invoiceData } = props;
  const [CurrencyRate, setCurrencyRate] = useState(
    props.poDetails.currency_rate
  );
  const { TextArea } = Input;
  const { Text } = Typography;
  const dateFormat = "YYYY/MM/DD";
  const token = getToken();
  const InvoiceAmt = useRef(invoiceData.invoice_amt);
  const currencySymb = useSelector((store: any) => store?.baseCurrency?.baseCurrency);
  const validationSchema = yup.object({
    invoice_amt: yup
      .number()
      .required("Please select currency & enter the invoice amount"),
    description: yup.string().trim().required("Please enter the description"),
    title: yup.string().trim().required("Please enter the title"),
    expected_date: yup
      .mixed()
      .required("Please enter the expected date")
      .nullable(false),
  });
  const dispatch: any = useDispatch();
  const currencyData = useSelector((store: any) => store?.currency?.data);
  const ratesData = useSelector((store: any) => store?.rate);

  useEffect(() => {
    if (currencyData.id) {
      dispatch(getRates(currencyData.id));
    }
  }, [currencyData]);

  useEffect(() => {
    if (ratesData.data && ratesData.data.mastercurrencies) {
      const temp: any = ratesData.data.mastercurrencies.filter(
        (currency: any) => {
          return (
            currency.currencyrates.currency_id === props.poDetails.currency_id
          );
        }
      );
      const retvalue: any =
        temp[0]?.currencyrates.rate ?? props.poDetails.currency_rate;

      setCurrencyRate(retvalue);
    }
  }, [ratesData]);

  async function rates(month: any, year: any) {
    if (month && year) {
      dispatch(getCurrencyRates(month, year));
    }
  }

  const roundTo = (number, decimals) => {
    const factor = Math.pow(10, decimals);
    return Math.round(number * factor) / factor;
  };

  return (
    <>
      <div className="pb-20">
        <Divider className="divider-margin" />
        <Formik
          initialValues={{
            invoice_id: invoiceData.id,
            title: invoiceData.title,
            invoice_amt: invoiceData.invoice_amt,
            invoice_amt_base: invoiceData.invoice_amt_base,
            description: invoiceData.description,
            expected_date: moment(invoiceData.expected_date),
          }}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            values = {
              ...values,
              invoice_amt: Number(values.invoice_amt),
              invoice_amt_base:
                Number(values.invoice_amt) * Number(CurrencyRate),
              expected_date: moment(values.expected_date).format(dateFormat),
              currency_rate: props.poDetails.currency_rate,
            };
            const maxValue = roundTo(
              props.poDetails.value +
              invoiceData.invoice_amt -
              props.currentInvoiceAmt, 2)
            if (values.invoice_amt > maxValue) {
              const notify = () => {
                notification.destroy();
                notification.error({
                  message: "Notification",
                  description:`Total invoice amount exceeds ${props?.totalAmount}`,
                  style: { color: "red" , width: 'auto'},
                });
                return;
              };
              {
                notify();
              }
            } else {
              AxiosConfig.put(`invoice/${invoiceData.po_id}`, values, {
                headers: { Authorization: `Bearer ${token}` },
              })
                .then((response) => {
                  if (response.status == 200) {
                    const notify = () => {
                      notification.destroy();
                      notification.open({
                        message: response.data.status.message,
                      });
                    };
                    {
                      notify();
                    }
                    props.getData()
                    props.cancelHandler()
                  }
                })
                .catch(function (error) {
                  console.log(error.response.data);
                  const notify = () => {
                    notification.destroy();
                    notification.open({
                      message: "",
                      description: error.response.data.status.message,
                      style: { color: "red" , width: 'auto' },
                    });
                  };
                  {
                    for (const key in values) {
                      delete values[key];
                    }
                    notify();
                  }
                });
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            errors,
            values,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="main-div">
                <Row gutter={16}>
                  <Col span={10}>
                    <label>{editInvoice.labelForInvoiceAmt}</label>
                    <Input
                      onChange={(event: any) => {
                        InvoiceAmt.current = event.target.value;
                        return handleChange(event);
                      }}
                      value={values.invoice_amt}
                      addonBefore={
                        props.currencyName[props.poDetails.currency_id]
                      }
                      name="invoice_amt"
                      placeholder="Enter the invoice amount"
                      className="mb--10 input--field"
                    />
                    <Text type="secondary">
                      {editInvoice.labelForConversionRate}
                      {currencySymb?.currency_symbol}
                      {values.expected_date
                        ? CurrencyRate
                        : props.poDetails.currency_rate}
                      | {currencySymb?.currency_code}:
                      {currencySymb?.currency_symbol}
                      {(values.expected_date
                        ? CurrencyRate
                        : props.poDetails.currency_rate) * InvoiceAmt.current}
                    </Text>
                    <p className="display_error">{errors.invoice_amt}</p>
                    <label>{editInvoice.labelForExpectedDtae}</label>
                    <DatePicker
                      defaultValue={values.expected_date}
                      format={dateFormat}
                      onChange={(val) => {
                        const date = moment(val, "YYYY/MM/DD");
                        const month = date.format("MMMM");
                        const year = date.format("YYYY");
                        rates(month, year);
                        setFieldValue("expected_date", val);
                      }}
                      placeholder="Select the expected date"
                      name="expected_date"
                      className="input--field"
                    />
                    <p className="display_error">{errors.expected_date}</p>
                  </Col>
                  <Col span={14}>
                    <label>{editInvoice.labelForInvoiceTitle}</label>
                    <Input
                      className="input--field"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      placeholder="Enter the Invoice Title"
                    />
                    <p className="display_error">{errors.title}</p>
                    <label>{editInvoice.labelFOrInvoiceDesc}</label>
                    <TextArea
                      className="textarea--field"
                      rows={3}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Enter the Invoice description"
                    />
                    <p className="display_error">{errors.description}</p>
                  </Col>
                </Row>
                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "0",
                  }}
                >
                  <ButtonReuse
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    value="Update"
                  ></ButtonReuse>
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Cancel"
                    onClick={() => {
                      resetForm();
                      props.cancelHandler(props.invoiceInstanceIndex);
                    }}
                  ></ButtonReuse>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default EditInvoice;
